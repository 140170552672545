var searchKeys = [{
  key: "videoid",
  label: "项目视频ID",
  placeholder: "项目视频ID",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "createdby",
  label: "创作者昵称",
  placeholder: "创作者昵称",
  required: false,
  rules: [],
  select: true,
  hidden: true,
  remote: true
}, {
  key: "status",
  label: "状态",
  placeholder: "请选择状态",
  required: false,
  rules: [],
  select: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var searchRecordsKeys = [{
  key: "dates",
  label: "领取时间",
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始时间", "截止时间"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
}, {
  key: "userid",
  label: "用户昵称",
  placeholder: "用户昵称",
  required: false,
  rules: [],
  select: true,
  hidden: true,
  remote: true
}, {
  key: "taskid",
  label: "关联视频任务ID",
  placeholder: "关联视频任务ID",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "taskcreatedby",
  label: "视频任务项目方昵称",
  placeholder: "视频任务项目方昵称",
  required: false,
  rules: [],
  select: true,
  hidden: true,
  remote: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys, searchRecordsKeys };