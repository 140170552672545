import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import Mixin from "@/utils/mixin";
import TableMixin from "@/utils/tableMixin";
import { searchKeys, columns } from "./config/index";
import { RainApi, WhitelistApi } from "@/api";
import Form from "./components/form.vue";
import { ADD } from "@/utils/buttons";
export default {
  name: "rain",
  components: {
    Form: Form
  },
  data: function data() {
    return {
      createForm: this.$form.createForm(this),
      loading: false,
      formLoading: false,
      timer: null,
      columns: columns,
      searchKeys: searchKeys,
      users: [],
      // 用户列表

      tipsDesc: "",
      isConfirmVisible: false,
      subLoading: false,
      action: "add",
      // 操作:add,edit,delete
      editId: undefined,
      modalVisible: false,
      source: {},
      // 表单编辑需要的字段

      defaultIndex: 0,
      types: [{
        id: 1,
        name: "待审核"
      }, {
        id: 2,
        name: "已过审"
      }],
      statusList: [{
        id: 1,
        name: "待审核"
      }, {
        id: 2,
        name: "审核通过"
      }, {
        id: 3,
        name: "待开始"
      }, {
        id: 4,
        name: "运行中"
      }, {
        id: 5,
        name: "已完结"
      }]
    };
  },
  mixins: [Mixin, TableMixin],
  computed: {
    getDataWithKey: function getDataWithKey() {
      var _this = this;
      return function (key) {
        switch (key) {
          case "status":
            return [{
              id: "",
              name: "全部"
            }].concat(_toConsumableArray(_this.getStatusList));
          case "createdby":
            return _this.users;
        }
      };
    },
    getStatusList: function getStatusList() {
      if (this.status == 1) {
        return this.statusList.filter(function (it) {
          return it.id == 1;
        });
      } else {
        return this.statusList.filter(function (it) {
          return it.id > 2;
        });
      }
    },
    modalTitle: function modalTitle() {
      return this.action == "add" ? "新增" : this.action == "view" ? "查看" : this.action == "check" ? "审核" : "";
    },
    status: function status() {
      return this.types[this.defaultIndex].id;
    }
  },
  created: function created() {
    this.getList();
  },
  activated: function activated() {},
  deactivated: function deactivated() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", []);
  },
  beforeDestroy: function beforeDestroy() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", []);
  },
  methods: {
    // 远程搜索功能
    onSearch: function onSearch(field, val) {
      var _this2 = this;
      if (!val) return;
      this.formLoading = true;
      this.users = [];
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(function () {
        WhitelistApi.searchUsernameV2({
          name: val
        }).then(function (res) {
          _this2.users = res;
        }).catch(function () {
          _this2.users = [];
        }).finally(function () {
          _this2.formLoading = false;
        });
      }, 500);
    },
    statusText: function statusText(status) {
      var it = this.statusList.find(function (item) {
        return item.id == status;
      });
      return (it === null || it === void 0 ? void 0 : it.name) || "";
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    // 取消启用/禁用
    handleDelCancle: function handleDelCancle() {
      this.isConfirmVisible = false;
      this.subLoading = false;
    },
    // 确定启用/禁用
    handleDelSubmit: function handleDelSubmit() {
      var _this3 = this;
      this.subLoading = true;
      RainApi.updateStatus(this.editId, {
        eventtype: 2
      }).then(function () {
        _this3.toast("操作成功", "success");
        _this3.isConfirmVisible = false;
        _this3.subLoading = false;
        _this3.getList();
      }).catch(function () {
        _this3.isConfirmVisible = false;
        _this3.subLoading = false;
      });
    },
    getList: function getList() {
      var _this4 = this;
      this.createForm.validateFields(function (err, values) {
        if (!err) {
          _this4.selectedRowKeys = [];
          _this4.loading = true;
          if (!values.status) delete values.status;
          var params = _objectSpread({
            page: _this4.page,
            size: _this4.size,
            status: _this4.status
          }, values);
          RainApi.list(_objectSpread({}, params)).then(function (res) {
            _this4.loading = false;
            _this4.dataSource = (res === null || res === void 0 ? void 0 : res.list) || [];
            _this4.total = (res === null || res === void 0 ? void 0 : res.total) || _this4.total;
          }).catch(function () {
            _this4.dataSource = [];
          }).finally(function () {
            setTimeout(function () {
              _this4.loading = false;
            }, 3000);
          });
        } else {
          console.log(err);
        }
      });
    },
    handleSearch: function handleSearch() {
      this.page = 1;
      this.getList();
    },
    handleReset: function handleReset() {
      this.page = 1;
      this.size = 20;
      this.createForm.resetFields();
      this.handleSearch();
    },
    handleAction: function handleAction(action, record) {
      this.action = action;
      this.editId = (record === null || record === void 0 ? void 0 : record.taskid) || "";
      if (action == "view") {
        this.modalVisible = true;
        this.source = record;
      } else if (action == ADD) {
        this.modalVisible = true;
        this.source = {};
      } else if (action == "deactive") {
        this.isConfirmVisible = true;
      } else {
        return;
      }
    },
    tabClick: function tabClick(e, index) {
      this.createForm.resetFields(["status"]);
      this.defaultIndex = index;
      this.page = 1;
      this.size = 20;
      this.handleSearch();
    },
    /*******表单相关start********/handleFormCancel: function handleFormCancel() {
      // console.log("取消");
      this.modalVisible = false;
    },
    handleFormSubmit: function handleFormSubmit() {
      this.modalVisible = false;
      this.getList();
    },
    /*******表单相关end********/
    /*******表格复选框start********/
    pageBtnClicked: function pageBtnClicked(module, action) {
      // console.log(action, ADD)
      if (action == ADD) {
        this.handleAction(action, {});
        return;
      }
      var ids = this.getAllCheckedKey("id");
      if (ids.length == 0) {
        this.toast("请先选择特征组", "error");
        return;
      }
      this.editId = ids;
      this.isConfirmVisible = true;
    } /*******表格复选框end********/
  }
};