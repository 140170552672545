import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      labelCol: {
        style: "width: 140px;"
      },
      model: _vm.item,
      form: _vm.form
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "项目视频ID"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["video", {
        rules: [{
          required: true,
          message: "请选择视频"
        }],
        initialValue: _vm.item.video
      }],
      expression: "[\n              'video',\n              {\n                rules: [{ required: true, message: '请选择视频' }],\n                initialValue: item.video,\n              },\n            ]"
    }],
    staticClass: "user-select",
    staticStyle: {
      width: "500px"
    },
    attrs: {
      dropdownClassName: "user-select",
      allowClear: true,
      "show-search": "",
      labelInValue: "",
      placeholder: "请输入视频ID进行搜索",
      "filter-option": false,
      disabled: _vm.isDisabled
    },
    on: {
      search: _vm.handleSearch
    }
  }, [_vm.uloading ? _c("a-spin", {
    attrs: {
      slot: "notFoundContent",
      size: "small"
    },
    slot: "notFoundContent"
  }) : _vm._e(), _vm._l(_vm.videoList, function (d) {
    return _c("a-select-option", {
      key: d.id,
      staticClass: "user-select-item",
      attrs: {
        title: d.title,
        value: d.id
      }
    }, [_vm._v(" " + _vm._s(d.title) + " ")]);
  })], 2)], 1), _c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "是否推荐流强推"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["isforcefulpush", {
        rules: [{
          required: true,
          message: "请选择是否推荐流强推!"
        }],
        initialValue: _vm.item.isforcefulpush
      }],
      expression: "[\n                  'isforcefulpush',\n                  {\n                    rules: [\n                      { required: true, message: '请选择是否推荐流强推!' },\n                    ],\n                    initialValue: item.isforcefulpush,\n                  },\n                ]"
    }],
    attrs: {
      disabled: _vm.isDisabled && _vm.status > 4
    }
  }, [_c("a-radio", {
    attrs: {
      value: true
    }
  }, [_c("span", [_vm._v("是")])]), _c("a-radio", {
    attrs: {
      value: false
    }
  }, [_c("span", [_vm._v("否")])])], 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_vm.form.getFieldValue("isforcefulpush") ? _c("a-form-item", [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["forcefulpushamount", {
        rules: [{
          required: true,
          message: "请输入推荐流强推位置数"
        }],
        initialValue: _vm.item.forcefulpushamount
      }],
      expression: "[\n                  'forcefulpushamount',\n                  {\n                    rules: [\n                      { required: true, message: '请输入推荐流强推位置数' },\n                    ],\n                    initialValue: item.forcefulpushamount,\n                  },\n                ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      min: 0,
      autoComplete: "off",
      placeholder: "请输入推荐流强推位置数",
      disabled: _vm.isDisabled && _vm.status > 4
    }
  })], 1) : _vm._e()], 1)], 1), _c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "目标展示量"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["viewtype", {
        rules: [{
          required: true,
          message: "请选择目标展示量!"
        }],
        initialValue: _vm.item.viewtype
      }],
      expression: "[\n                  'viewtype',\n                  {\n                    rules: [{ required: true, message: '请选择目标展示量!' }],\n                    initialValue: item.viewtype,\n                  },\n                ]"
    }],
    attrs: {
      disabled: _vm.isDisabled && _vm.status > 4
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_c("span", [_vm._v("固定量")])]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_c("span", [_vm._v("持续运行")])])], 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_vm.form.getFieldValue("viewtype") == 1 ? _c("a-form-item", [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["viewamount", {
        rules: [{
          type: "integer",
          min: _vm.item.playedamount === undefined ? 1 : _vm.item.playedamount + 1,
          required: true,
          message: "请输入目标展示量!"
        }],
        initialValue: _vm.item.viewamount
      }],
      expression: "[\n                  'viewamount',\n                  {\n                    rules: [{ type: 'integer', min: item.playedamount === undefined ? 1 : item.playedamount + 1, required: true, message: '请输入目标展示量!' }],\n                    initialValue: item.viewamount,\n                  },\n                ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      autoComplete: "off",
      placeholder: "请输入目标展示量",
      min: _vm.item.playedamount === undefined ? 1 : _vm.item.playedamount + 1,
      disabled: _vm.isDisabled && _vm.status > 4
    }
  })], 1) : _vm._e()], 1)], 1), _c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "运行周期"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["runcycletype", {
        rules: [{
          required: true,
          message: "请选择运行周期!"
        }],
        initialValue: _vm.item.runcycletype
      }],
      expression: "[\n                  'runcycletype',\n                  {\n                    rules: [{ required: true, message: '请选择运行周期!' }],\n                    initialValue: item.runcycletype,\n                  },\n                ]"
    }],
    attrs: {
      disabled: _vm.isDisabled && _vm.status > 4
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_c("span", [_vm._v("固定周期")])]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_c("span", [_vm._v("持续运行")])])], 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_vm.form.getFieldValue("runcycletype") == 1 ? _c("a-form-item", [_c("a-range-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["date", {
        rules: [{
          required: true,
          message: "请选择时间范围!"
        }],
        initialValue: _vm.item.date
      }],
      expression: "[\n                  'date',\n                  {\n                    rules: [{ required: true, message: '请选择时间范围!' }],\n                    initialValue: item.date,\n                  },\n                ]"
    }],
    attrs: {
      disabled: _vm.isDisabled && _vm.status > 4,
      format: "YYYY-MM-DD HH:mm:ss",
      "show-time": ""
    }
  })], 1) : _vm._e()], 1)], 1), _c("a-row", [_c("a-col", {
    attrs: {
      span: 10
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "奖金池"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["rewardtype", {
        rules: [{
          required: true,
          message: "请选择奖金池!"
        }],
        initialValue: _vm.item.rewardtype || 1
      }],
      expression: "[\n                  'rewardtype',\n                  {\n                    rules: [{ required: true, message: '请选择奖金池!' }],\n                    initialValue: item.rewardtype || 1,\n                  },\n                ]"
    }],
    attrs: {
      disabled: _vm.isDisabled
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_c("span", [_vm._v("USDT")])])], 1)], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["rewardamount", {
        rules: [{
          required: true,
          message: "请输入奖金池数量!"
        }, {
          validator: _vm.validateRewardamountRule
        }],
        validateFirst: true,
        initialValue: _vm.item.rewardamount
      }],
      expression: "[\n                  'rewardamount',\n                  {\n                    rules: [\n                      { required: true, message: '请输入奖金池数量!' },\n                      { validator: validateRewardamountRule }\n                    ],\n                    validateFirst: true,\n                    initialValue: item.rewardamount,\n                  },\n                ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      autoComplete: "off",
      placeholder: "请输入奖金池数量",
      min: 0,
      disabled: _vm.isDisabled
    }
  })], 1)], 1)], 1), _c("a-row", [_c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "用户随机奖励范围"
    }
  }, [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["rewardrandomamountmin", {
        rules: [{
          required: true,
          message: "请输入用户随机奖励范围!"
        }, {
          validator: _vm.validateGltZeroPriceRule
        }, {
          validator: _vm.validateCompareRule
        }],
        validateFirst: true,
        initialValue: _vm.item.rewardrandomamountmin || 0
      }],
      expression: "[\n                  'rewardrandomamountmin',\n                  {\n                    rules: [\n                      { required: true, message: '请输入用户随机奖励范围!' },\n                      { validator: validateGltZeroPriceRule },\n                      { validator: validateCompareRule },\n                    ],\n                    validateFirst: true,\n                    initialValue: item.rewardrandomamountmin || 0,\n                  },\n                ]"
    }],
    staticStyle: {
      width: "190px",
      "text-align": "center"
    },
    attrs: {
      min: 0,
      placeholder: "最小值数量，默认0",
      name: "rewardrandomamountmin",
      disabled: _vm.isDisabled
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 1
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "32px",
      "text-align": "center"
    }
  }, [_vm._v("~")])]), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["rewardrandomamountmax", {
        rules: [{
          required: true,
          message: "请输入用户随机奖励范围!"
        }, {
          validator: _vm.validateGltZeroPriceRule
        }, {
          validator: _vm.validateCompareRule
        }, {
          validator: _vm.validateRewardamountMaxRule
        }],
        validateFirst: true,
        initialValue: _vm.item.rewardrandomamountmax || 1
      }],
      expression: "[\n                  'rewardrandomamountmax',\n                  {\n                    rules: [\n                      { required: true, message: '请输入用户随机奖励范围!' },\n                      { validator: validateGltZeroPriceRule },\n                      { validator: validateCompareRule },\n                      { validator: validateRewardamountMaxRule },\n                    ],\n                    validateFirst: true,\n                    initialValue: item.rewardrandomamountmax || 1,\n                  },\n                ]"
    }],
    staticStyle: {
      width: "190px",
      "text-align": "center"
    },
    attrs: {
      min: 0,
      placeholder: "最大值数量，默认1",
      name: "rewardrandomamountmax",
      disabled: _vm.isDisabled
    }
  })], 1)], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "用户单次点击中奖率"
    }
  }, [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["singleclickchance", {
        rules: [{
          required: true,
          message: "请输入用户单次点击中奖率!"
        }, {
          pattern: /^\d+$/,
          message: "只能输入整数!"
        }],
        validateFirst: true,
        initialValue: _vm.item.singleclickchance || 50
      }],
      expression: "[\n              'singleclickchance',\n              {\n                rules: [\n                  { required: true, message: '请输入用户单次点击中奖率!' },\n                  { pattern: /^\\d+$/, message: '只能输入整数!' },\n                ],\n                validateFirst: true,\n                initialValue: item.singleclickchance || 50,\n              },\n            ]"
    }],
    attrs: {
      min: 0,
      max: 100,
      formatter: function formatter(value) {
        return "".concat(value, "%");
      },
      parser: function parser(value) {
        return value.replace("%", "");
      },
      name: "singleclickchance",
      disabled: _vm.isDisabled
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "高额奖励配置"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["ishighrewardconfig", {
        rules: [{
          required: true,
          message: "请选择高额奖励配置!"
        }],
        initialValue: _vm.item.ishighrewardconfig
      }],
      expression: "[\n              'ishighrewardconfig',\n              {\n                rules: [{ required: true, message: '请选择高额奖励配置!' }],\n                initialValue: item.ishighrewardconfig,\n              },\n            ]"
    }],
    attrs: {
      disabled: _vm.isDisabled
    }
  }, [_c("a-radio", {
    attrs: {
      value: true
    }
  }, [_c("span", [_vm._v("打开")])]), _c("a-radio", {
    attrs: {
      value: false
    }
  }, [_c("span", [_vm._v("关闭")])])], 1)], 1), _vm.form.getFieldValue("ishighrewardconfig") ? _c("a-form-item", {
    attrs: {
      label: "发放个数"
    }
  }, [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["highrewardcount", {
        rules: [{
          required: true,
          message: "请输入发放个数!"
        }, {
          pattern: /^\d+$/,
          message: "只能输入整数!"
        }, {
          validator: _vm.validateRule2
        }],
        validateFirst: true,
        initialValue: _vm.item.highrewardcount
      }],
      expression: "[\n              'highrewardcount',\n              {\n                rules: [\n                  { required: true, message: '请输入发放个数!' },\n                  { pattern: /^\\d+$/, message: '只能输入整数!' },\n                  { validator: validateRule2 },\n                ],\n                validateFirst: true,\n                initialValue: item.highrewardcount,\n              },\n            ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      autoComplete: "off",
      placeholder: "请输入",
      min: 1,
      disabled: _vm.isDisabled
    }
  })], 1) : _vm._e(), _vm.form.getFieldValue("ishighrewardconfig") ? _c("a-row", [_c("a-col", {
    attrs: {
      span: 11
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "发放金额"
    }
  }, [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["highrewardamountmin", {
        rules: [{
          required: true,
          message: "请输入"
        }, {
          validator: _vm.validateGltZeroPriceRule
        }, {
          validator: _vm.validateCompareRule
        }, {
          validator: _vm.validateAmountRule
        }],
        validateFirst: true,
        initialValue: _vm.item.highrewardamountmin
      }],
      expression: "[\n                  'highrewardamountmin',\n                  {\n                    rules: [\n                      { required: true, message: '请输入' },\n                      { validator: validateGltZeroPriceRule },\n                      { validator: validateCompareRule },\n                      { validator: validateAmountRule },\n                    ],\n                    validateFirst: true,\n                    initialValue: item.highrewardamountmin,\n                  },\n                ]"
    }],
    staticStyle: {
      width: "190px",
      "text-align": "center"
    },
    attrs: {
      min: 0,
      placeholder: "最小值数量",
      disabled: _vm.isDisabled
    }
  })], 1)], 1), _c("a-col", {
    attrs: {
      span: 1
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "32px",
      "text-align": "center"
    }
  }, [_vm._v("~")])]), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-item", [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["highrewardamountmax", {
        rules: [{
          required: true,
          message: "请输入"
        }, {
          validator: _vm.validateGltZeroPriceRule
        }, {
          validator: _vm.validateCompareRule
        }, {
          validator: _vm.validateRule2
        }],
        validateFirst: true,
        initialValue: _vm.item.highrewardamountmax
      }],
      expression: "[\n                  'highrewardamountmax',\n                  {\n                    rules: [\n                      { required: true, message: '请输入' },\n                      { validator: validateGltZeroPriceRule },\n                      { validator: validateCompareRule },\n                      { validator: validateRule2 },\n                    ],\n                    validateFirst: true,\n                    initialValue: item.highrewardamountmax,\n                  },\n                ]"
    }],
    staticStyle: {
      width: "190px",
      "text-align": "center"
    },
    attrs: {
      min: 0,
      placeholder: "最大值数量",
      disabled: _vm.isDisabled
    }
  })], 1)], 1)], 1) : _vm._e(), _c("a-form-item", {
    attrs: {
      label: "项目导流跳转链接"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["jumpurl", {
        rules: [{
          pattern: /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/,
          message: "请正确填写项目导流跳转链接!"
        }],
        initialValue: _vm.item.jumpurl
      }],
      expression: "[\n              'jumpurl',\n              {\n                rules: [\n                  {\n                    pattern:\n                      /^(((ht|f)tps?):\\/\\/)?([^!@#$%^&*?.\\s-]([^!@#$%^&*?.\\s]{0,63}[^!@#$%^&*?.\\s])?\\.)+[a-z]{2,6}\\/?/,\n                    message: '请正确填写项目导流跳转链接!',\n                  },\n                ],\n                initialValue: item.jumpurl,\n              },\n            ]"
    }],
    attrs: {
      placeholder: "填写链接，不填写默认不展示导流位",
      disabled: _vm.isDisabled,
      autoComplete: "off"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "项目导流配置文案"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["jumptitle", {
        rules: [{
          required: !_vm.isDisabled && _vm.form.getFieldValue("jumpurl") ? true : false,
          type: "string",
          max: 30,
          message: "导流配置文案不能超过30个字符"
        }],
        initialValue: _vm.item.jumptitle
      }],
      expression: "[\n              'jumptitle',\n              {\n                rules: [\n                  {\n                    required: !isDisabled && form.getFieldValue('jumpurl') ? true : false,\n                    type: 'string',\n                    max: 30,\n                    message: '导流配置文案不能超过30个字符',\n                  },\n                ],\n                initialValue: item.jumptitle,\n              },\n            ]"
    }],
    attrs: {
      placeholder: "最多不超过30个字符",
      disabled: _vm.isDisabled,
      maxLength: 30,
      autoComplete: "off"
    }
  })], 1)], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("span", [_c("a-button", {
    staticClass: "btn btn_l",
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), !_vm.editId ? _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary",
      loading: _vm.action == "add" && _vm.disabledLoading
    },
    on: {
      click: function click($event) {
        return _vm.submit("add");
      }
    }
  }, [_vm._v("创建")]) : _vm._e(), _vm.status == 3 || _vm.status == 4 ? _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["rain:btn:edit"],
      expression: "['rain:btn:edit']"
    }],
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary",
      loading: _vm.action == "edit" && _vm.disabledLoading,
      disabled: _vm.disabledLoading && _vm.action == "check"
    },
    on: {
      click: function click($event) {
        return _vm.submit("edit");
      }
    }
  }, [_vm._v("保存")]) : _vm._e(), _vm.editId && _vm.status == 1 ? _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["rain:btn:check"],
      expression: "['rain:btn:check']"
    }],
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary",
      loading: _vm.action == "check" && _vm.disabledLoading,
      disabled: _vm.disabledLoading && _vm.action == "edit"
    },
    on: {
      click: function click($event) {
        return _vm.submit("check");
      }
    }
  }, [_vm._v("过审")]) : _vm._e()], 1)])], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };