import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { mapGetters } from "vuex";
import { RainApi, DcVideoApi } from "@/api";
import Mixin from "@/utils/mixin";
export default {
  name: "rainForm",
  components: {},
  props: {
    editId: [String, Number],
    status: [Number]
  },
  mixins: [Mixin],
  data: function data() {
    return {
      form: this.$form.createForm(this),
      spinning: false,
      disabledLoading: false,
      item: {},
      videoList: [],
      uloading: false,
      timer: null,
      action: ""
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters("dcVideo", ["statusListObj"])), {}, {
    isDisabled: function isDisabled() {
      return !this.editId ? false : this.status < 4 ? false : true;
    } // status(){
    //   return this.item.status || 1;
    // }
  }),
  created: function created() {
    // console.log(this.status, this.editId);
    if (this.editId) {
      this.find();
    }
  },
  methods: {
    // 仅用于比较验证提示
    validateCompareRule: function validateCompareRule(rule, value, callback) {
      var field = rule.field;
      var isMin = field.includes("min");
      var targetField = isMin ? field.replace("min", "max") : field.replace("max", "min");
      var targetValue = this.form.getFieldValue(targetField);
      // console.log(field, targetField);

      if (isNaN(targetValue)) {
        callback("".concat(isMin ? '较大值' : '较小值', "\u672A\u586B\u5199\u6216\u683C\u5F0F\u9519\u8BEF"));
      }
      if (isMin && Number(value) > Number(targetValue)) callback("较小值大于较大值");
      if (!isMin && Number(value) < Number(targetValue)) callback("较大值小于较小值");
      if (this.form.getFieldError(targetField)) {
        this.form.validateFields([targetField]);
      }
      callback();
    },
    validateAmountRule: function validateAmountRule(rule, value, callback) {
      var targetField = "rewardrandomamountmax";
      var targetValue = this.form.getFieldValue(targetField);
      if (isNaN(value)) callback("最小值格式错误");
      if (isNaN(targetValue)) callback();
      if (Number(value) <= Number(targetValue)) callback("不能小于或等于用户随机奖励范围最大值");
      callback();
    },
    validateRewardamountRule: function validateRewardamountRule(rule, value, callback) {
      var switchField = 'ishighrewardconfig';
      var targetValue1 = this.form.getFieldValue('highrewardcount');
      var targetValue2 = this.form.getFieldValue('highrewardamountmax');
      var targetValue3 = this.form.getFieldValue('rewardrandomamountmax');
      if (this.form.getFieldValue(switchField)) {
        if (isNaN(targetValue1) || isNaN(targetValue2)) callback();else if (Number(value) < Number(targetValue1) * Number(targetValue2)) callback('高额奖励发放个数*发放金额最大值数量必须<=奖金池');else {
          if (this.form.getFieldError('highrewardcount')) this.form.validateFields(['highrewardcount']);
          if (this.form.getFieldError('highrewardamountmax')) this.form.validateFields(['highrewardamountmax']);
        }
      }
      if (Number(value) < Number(targetValue3)) callback('随机奖励范围最大值 需要 <= 奖金池');
      if (this.form.getFieldError('rewardrandomamountmax')) this.form.validateFields(['rewardrandomamountmax']);
      callback();
    },
    // 随机奖励范围最大值检验
    validateRewardamountMaxRule: function validateRewardamountMaxRule(rule, value, callback) {
      var targetField = "rewardamount";
      var targetValue = this.form.getFieldValue(targetField);
      if (Number(value) > Number(targetValue)) callback("不能大于奖金池");
      if (this.form.getFieldError(targetField)) this.form.validateFields([targetField]);
      callback();
    },
    //  高额奖励发放个数*发放金额最大值数量<=奖金池
    validateRule2: function validateRule2(rule, value, callback) {
      var field = rule.field;
      var field2 = field == 'highrewardcount' ? 'highrewardamountmax' : 'highrewardcount';
      var value2 = this.form.getFieldValue(field2);
      var targetField = 'rewardamount';
      var targetValue = this.form.getFieldValue(targetField);
      if (isNaN(targetValue) || isNaN(value) || isNaN(value2)) callback();else if (Number(targetValue) < Number(value) * Number(value2)) callback('高额奖励发放个数*发放金额最大值数量必须<=奖金池');
      if (this.form.getFieldError(field2)) this.form.validateFields([field2]);
      callback();
    },
    find: function find() {
      var _this = this;
      this.disabledLoading = true;
      this.spinning = true;
      RainApi.find(this.editId, {}).then(function (res) {
        if (res.runcyclestarttime && res.runcycleendtime) {
          res.date = [_this.$moment(res.runcyclestarttime), _this.$moment(res.runcycleendtime)];
        }
        res.video = {
          key: res.videoid,
          label: res.videoname
        };
        _this.item = res;
        _this.disabledLoading = false;
      }).catch(function (err) {
        _this.toast(err || "接口报错", "error");
      }).finally(function () {
        _this.spinning = false;
      });
    },
    cancle: function cancle() {
      this.$emit("cancel");
    },
    submit: function submit(action) {
      var _this2 = this;
      this.action = action;
      if (this.disabledLoading) return;
      this.form.validateFields(function (err, values) {
        if (!err) {
          _this2.disabledLoading = true;
          var params = {};
          var method = _this2.editId ? "update" : "add";
          params = _objectSpread({}, values);
          if (values.date) {
            params.runcyclestarttime = params.date[0].format("YYYY-MM-DD HH:mm:ss");
            params.runcycleendtime = params.date[1].format("YYYY-MM-DD HH:mm:ss");
            delete params.date;
          }
          if (values.video) {
            params.videoid = values.video.key;
            params.videoname = values.video.label;
            delete params.video;
          }
          var fieldStr = ["rewardamount", "rewardrandomamountmin", "rewardrandomamountmax", "singleclickchance", "highrewardamountmin", "highrewardamountmax"];
          for (var field in params) {
            if (fieldStr.includes(field)) params[field] = "".concat(params[field]);
          }
          if (_this2.status == 4) {
            var fillable = ["isforcefulpush", "forcefulpushamount", "viewtype", "viewamount", "runcycletype", "runcyclestarttime", "runcycleendtime"];
            for (var _field in params) {
              if (!fillable.includes(_field)) delete params[_field];
            }
          }
          var req;
          if (_this2.editId) {
            params.taskid = _this2.editId;
            if (action == 'check') {
              req = RainApi.updateStatus(_this2.editId, {
                eventtype: 1,
                tokenrain: params
              });
            } else {
              req = RainApi[method](_this2.editId, params);
            }
          } else {
            req = RainApi[method](params);
          }
          req.then(function () {
            _this2.toast("操作成功", "success");
            _this2.$emit("submit");
          }).catch(function (err) {
            _this2.toast((err === null || err === void 0 ? void 0 : err.msg) || "操作失败", "error");
          }).finally(function () {
            setTimeout(function () {
              _this2.disabledLoading = false;
            }, 1000);
          });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    handleSearch: function handleSearch(val) {
      var _this3 = this;
      this.uloading = true;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(function () {
        if (!val) {
          _this3.videoList = [];
          _this3.uloading = false;
          return;
        }
        DcVideoApi.getreviewed({
          videoids: [val]
        }).then(function (res) {
          _this3.videoList = (res === null || res === void 0 ? void 0 : res.list) || [];
        }).catch(function () {
          _this3.videoList = [];
        }).finally(function () {
          _this3.uloading = false;
        });
      }, 500);
    }
  }
};