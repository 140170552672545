var columns = [{
  title: "任务ID",
  dataIndex: "taskid",
  key: "taskid",
  width: "15%",
  scopedSlots: {
    customRender: "taskid"
  }
}, {
  title: "项目视频ID",
  dataIndex: "videoid",
  key: "videoid",
  width: "15%",
  scopedSlots: {
    customRender: "videoid"
  }
}, {
  title: "创作者昵称",
  dataIndex: "createdby",
  key: "createdby",
  width: "10%",
  scopedSlots: {
    customRender: "createdby"
  }
}, {
  title: "项目视频名称",
  dataIndex: "videoname",
  key: "videoname",
  width: "18%",
  scopedSlots: {
    customRender: "videoname"
  }
}, {
  title: "奖金池",
  dataIndex: "rewardamount",
  key: "rewardamount",
  width: "12%",
  scopedSlots: {
    customRender: "rewardamount"
  }
}, {
  title: '剩余资金池',
  dataIndex: 'remainderewardamount',
  key: "remainderewardamount",
  width: "10%",
  scopedSlots: {
    customRender: "remainderewardamount"
  }
}, {
  title: '展示量',
  dataIndex: 'playedamount',
  key: "playedamount",
  width: "10%",
  scopedSlots: {
    customRender: "playedamount"
  }
}, {
  title: "创建时间",
  dataIndex: "createdtime",
  key: "createdtime",
  width: "15%",
  scopedSlots: {
    customRender: "createdtime"
  }
}, {
  title: "状态",
  dataIndex: "status",
  key: "status",
  width: "10%",
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  fixed: "right",
  width: 150,
  scopedSlots: {
    customRender: "action"
  }
}];
var recordsColumns = [{
  title: "记录ID",
  dataIndex: "id",
  key: "id",
  width: "10%",
  scopedSlots: {
    customRender: "id"
  }
}, {
  title: "用户昵称",
  dataIndex: "username",
  key: "username",
  width: "15%",
  scopedSlots: {
    customRender: "username"
  }
}, {
  title: "领取奖励数量",
  dataIndex: "rewardamount",
  key: "rewardamount",
  width: "15%",
  scopedSlots: {
    customRender: "rewardamount"
  }
}, {
  title: "领取奖励币种",
  dataIndex: "rewardtype",
  key: "rewardtype",
  width: "15%",
  scopedSlots: {
    customRender: "rewardtype"
  }
}, {
  title: "关联视频任务ID",
  dataIndex: "taskid",
  key: "taskid",
  width: "15%",
  scopedSlots: {
    customRender: "taskid"
  }
}, {
  title: "视频任务项目方昵称",
  dataIndex: "taskcreatedbyusername",
  key: "taskcreatedbyusername",
  width: "15%",
  scopedSlots: {
    customRender: "taskcreatedbyusername"
  }
}, {
  title: "领取时间",
  dataIndex: "createdtime",
  key: "createdtime",
  width: "15%",
  scopedSlots: {
    customRender: "createdtime"
  }
}];
export { columns, recordsColumns };